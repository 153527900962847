<template>
    <div class="container">
        <p class="u--grow">
            A new version of Fernand is available. <br />
            Please refresh your browser.
        </p>
        <a href="javascript:;" title="Refresh Fernand" @click="refresh" class="button button--secondary button--small u--ml8">Refresh</a>
    </div>
</template>

<script>
export default {
    methods: {
        refresh () {
            window.location.reload(true)
        }
    }
}
</script>
